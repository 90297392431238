import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../context/Enviroment";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import "./CurrentInventoryBalance.scss";
import Loading from "../../../Components/Loading/Loading";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import { Table } from "react-bootstrap";
import CustomPagination from "../../../Components/Paginator/Paginator";

export default function CurrentInventoryBalance() {
  const [services, setServices] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const { t } = useTranslation();
  const [classNames, setClassNames] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState(null);
  const [stockBalanceData, setStockBalanceData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);



  //get services
  const fetchServices = () => {
    fetch(`${BASE_URL}/ProductServiceProvider/GetServicesDataFrom_thdb`)
      .then(response => response.json())
      .then(data => {
        setServices(data.resultList);
        console.log(data.resultList);
      })
      .catch(error => console.error("Error fetching categories:", error));
  };

  //get cards
  const fetchCardsName = async serviceId => {
    try {
      const response = await fetch(`${BASE_URL}/ProductServiceProvider/GetClassNameDataFrom_thdb?service_id=${serviceId}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setClassNames(data.resultList);
    } catch (error) {
      console.error("Error fetching class names:", error.message);
    }
  };

  //api request
  const fetchData = async () => {
    const userToken = localStorage.getItem("userToken");

    setLoading(true);

    try {
      const requestBody = {
        service_id: selectedValue.service_id,
        class_name: selectedClassName.class_name
      };

      const response = await fetch(`${BASE_URL}/CurrentStockBalance/GetCurrentStockBalanceData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      setStockBalanceData(data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleServiceChange = e => {
    const selectedServiceId = e.value.service_id;
    setSelectedValue(e.value);
    fetchCardsName(selectedServiceId);
  };

  const handleClassNameChange = e => {
    setSelectedClassName(e.value);
  };

  //handle pagination

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    stockBalanceData && stockBalanceData && stockBalanceData.length > 0
      ? stockBalanceData.slice(indexOfFirstItem, indexOfLastItem)
      : [];
  const totalRecords = stockBalanceData && stockBalanceData ? stockBalanceData.length : 0;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  //handle excel file to export
  const columnsToExport = stockBalanceData?.map(item => ({
    [t("company")]: item.service_id,
    [t("note")]: item.class_name,
    [t("cost")]: item.tclass_cost,
    [t("price")]: item.tclass_price,
    [t("TotalCount")]: item.tcount
  }));
  return (
    <>
      <h3 className="text-main m-3 report-head">{t("رصيد المخزون الحالي")}</h3>
      <div className="card mb-4 custom-card  m-3 d-flex flex-row p-4">
        <div className="row">
          <div className="col-md-9 col-sm-12 d-flex flex-row gap-4">
            <div className="service-dropdown">
              <span className="my-2">اسم الصنف</span>
              <Dropdown
                value={selectedValue}
                options={services}
                onChange={handleServiceChange}
                optionLabel="fullName"
                placeholder="Select a category"
                filter
                filterBy="fullName"
                className="mt-2"
              />
            </div>

            <div>
              <span className="">اسم الكارت</span>
              <Dropdown
                value={selectedClassName}
                options={classNames}
                onChange={handleClassNameChange}
                optionLabel="class_name"
                placeholder="Select a class name"
                filter
                filterBy="class_name"
                className="mt-2"
              />
            </div>

            <div className="targetBtn-container d-flex flex-row  gap-3 mt-4">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2 mt-2"
                onClick={fetchData}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("company")}</th>
                  <th>{t("note")}</th>
                  <th>{t("cost")}</th>
                  <th>{t("price")}</th>
                  <th>{t("TotalCount")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {currentItems?.map((order, index) => (
                  <tr key={index}>
                    <td width="100px" className="ml-4">
                      {order.service_id}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.class_name}
                    </td>
                    <td width="100px" className="ml-4">
                      {parseFloat(order.tclass_cost).toFixed(2)}
                    </td>
                    <td width="100px" className="ml-4">
                      {parseFloat(order.tclass_price).toFixed(2)}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.tcount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            indexOfLastRow={indexOfLastItem}
            reportData={stockBalanceData}
          />
        </div>
      </div>
    </>
  );
}
