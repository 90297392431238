import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import "./GetOrders.scss";
import Loading from "../../../Components/Loading/Loading";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { BASE_URL } from "../../../context/Enviroment";
import { format } from "date-fns";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../Components/Paginator/Paginator";

const GetOrders = () => {
  const [ordersData, setOrdersData] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const userToken = localStorage.getItem("userToken");

  const fetchData = async () => {
    setLoading(true);

    try {
      const fromUnixTime = startDate ? startDate.toISOString() : null;
      const toUnixTime = endDate ? endDate.toISOString() : null;

      const requestBody = {
        FromUnixTime: fromUnixTime,
        ToUnixTime: toUnixTime,
        pageCount: 1000,
        orderType: selectedValue
        // invoiceHeaderId: invoiceId
      };

      const response = await fetch(`${BASE_URL}/LikeCard/GetOrdersHeadersFromDb`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setOrdersData(data.DateSet.Orders);
      // console.log(data.DateSet.Orders);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //handle pagination
  const [currentPageOrders, setCurrentPageOrders] = useState(1);
  const [itemsPerPageOrders] = useState(10);

  const indexOfLastItemOrders = currentPageOrders * itemsPerPageOrders;
  const indexOfFirstItemOrders = indexOfLastItemOrders - itemsPerPageOrders;
  const currentItemsOrders =
    ordersData && Array.isArray(ordersData) && ordersData.length > 0
      ? ordersData.slice(indexOfFirstItemOrders, indexOfLastItemOrders)
      : [];

  const totalRecordsOrders = ordersData && Array.isArray(ordersData) ? ordersData.length : 0;
  const totalPagesOrders = Math.ceil(totalRecordsOrders / itemsPerPageOrders);

  const handlePageChangeOrders = newPage => {
    setCurrentPageOrders(newPage);
  };

  //handle options selection
  const [selectedValue, setSelectedValue] = useState(null);

  const options = [{ label: "الاقدم", value: "1" }, { label: "الاحدث", value: "2" }];
  const handleDropdownChange = event => {
    setSelectedValue(event.value);
  };

  //format date and time
  const formatDate = dateString => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  const formatTime = dateString => {
    const formattedTime = format(new Date(dateString), "h:mm a");
    return formattedTime;
  };

  const columnsToExport = ordersData?.map(item => ({
    [t("Id")]: item.Id,
    [t("ServiceId")]: item.ServiceId,
    [t("productNumber")]: item.ProductId,
    [t("OrderCount")]: item.OrderCount,
    [t("RetrivedCount")]: item.RetrivedCount,
    [t("ClassName")]: item.ClassName,
    [t("ClassNo")]: item.ClassNo,
    [t("sellPrice")]: item.ProductPrice,
    [t("cost")]: item.ProductCost,
    [t("date")]: formatDate(item.CreationDate),
    [t("time")]: formatTime(item.CreationDate)
  }));

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("ordersView")}</h3>
      <div className="card mb-4 custom-card m-3 d-flex flex-column flex-sm-row p-4">
        <div className="row">
          <div className="col-md-9 col-sm-12 d-flex flex-column flex-sm-row gap-4">
            {/* select sorting type */}
            <div className="custom-dropdown  w-100">
              <span className="h6">{t("sortingType")}: </span>
              <Dropdown
                id="selectOption"
                value={selectedValue}
                options={options}
                onChange={handleDropdownChange}
                placeholder="Select an option"
                className="w-100"
              />
            </div>

            {/* start date input */}
            <div className="custom-dropdown  w-100 d-flex flex-column">
              <span className="h6">{t("from")}: </span>
              <div className="p-input-icon-left">
                <i className="pi pi-calendar calendar-icon text-main" />
                <Calendar
                  className="tergetReport-calendar w-100"
                  dateFormat="dd/mm/yy"
                  placeholder={t("choose")}
                  value={startDate}
                  onChange={e => setStartDate(e.value)}
                />
              </div>
            </div>

            {/* end date input */}
            <div className="custom-dropdown  w-100 d-flex flex-column">
              <span className="ml-2 h6">{t("to")}: </span>
              <div className="p-input-icon-left">
                <i className="pi pi-calendar calendar-icon text-main" />
                <Calendar
                  className="tergetReport-calendar w-100"
                  dateFormat="dd/mm/yy"
                  placeholder={t("choose")}
                  value={endDate}
                  onChange={e => setEndDate(e.value)}
                />
              </div>
            </div>
          </div>

          {/* search btn */}
          <div className="col-md-3 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-column p-4 gap-3">
              <Button
                label={t("search")}
                style={{
                  border: "none",
                  backgroundColor: "#007bff",
                  color: "#fff"
                }}
                className="rounded-2 btn w-100 mb-2"
                onClick={fetchData}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("Id")}</th>
                  <th>{t("ServiceId")}</th>
                  <th>{t("productNumber")}</th>
                  <th>{t("OrderCount")}</th>
                  <th>{t("RetrivedCount")}</th>
                  <th>{t("ClassName")}</th>
                  <th>{t("ClassNo")}</th>
                  <th>{t("sellPrice")}</th>
                  <th>{t("cost")}</th>
                  <th>{t("date")}</th>
                  <th>{t("time")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {currentItemsOrders.map((order, index) => (
                  <tr key={index}>
                    <td width="100px" className="ml-4">
                      {order.Id}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.ServiceId}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.ProductId}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.OrderCount}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.RetrivedCount}
                    </td>
                    <td width="200px" className="ml-4">
                      {order.ClassName}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.ClassNo}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.ProductPrice}
                    </td>
                    <td width="100px" className="ml-4">
                      {order.ProductCost}
                    </td>
                    <td>{formatDate(order.CreationDate)}</td>
                    <td dir="ltr">{formatTime(order.CreationDate)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <CustomPagination
            currentPage={currentPageOrders}
            totalPages={totalPagesOrders}
            handlePageChange={handlePageChangeOrders}
            indexOfLastRow={indexOfLastItemOrders}
            reportData={ordersData}
          />{" "}
        </div>
      </div>
    </div>
  );
};

export default GetOrders;
