import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { BASE_URL } from "../../../context/Enviroment";
import Loading from "../../../Components/Loading/Loading";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../Components/Paginator/Paginator";

const GetProducts = () => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    const userToken = localStorage.getItem("userToken");

    fetch(`${BASE_URL}/LikeCard/GetCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    }).then(response => response.json())
      .then(data => {
        const flattenedCategories = flattenCategories(data?.DateSet?.data || []);
        setCategories(flattenedCategories);
      })
      .catch(error => console.error("Error fetching categories:", error));
  };

  const flattenCategories = categories => {
    return categories.reduce((flattened, category) => {
      flattened.push(category);
      if (category.childs && category.childs.length > 0) {
        flattened = flattened.concat(category.childs.map(child => ({ ...child, isChild: true })));
      }
      return flattened;
    }, []);
  };

  const handleButtonClick = async categoryId => {
    setLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/LikeCard/GetProductsByCategoryId?categoryId=${categoryId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      const data = await response.json();

      if (data.DateSet.message === "No available products") {
        setProducts([]);
      } else if (data.ResponseMessage === "Sucess" && data.DateSet && data.DateSet.data) {
        setProducts(data.DateSet.data);
      } else {
        console.error("Unexpected data structure:", data);
      }
    } catch (error) {
      console.error("Error fetching products:", error.message);
    } finally {
      setLoading(false);
      setShowData(true);
    }
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleCategoryChange = e => {
    const selectedCategory = e.value;
    console.log(selectedCategory.id);
    setSelectedCategoryId(selectedCategory.id);
    setSelectedValue(selectedCategory);
  };

  //handle excel exporter
  const columnsToExport = products.map(item => ({
    [t("productNumber")]: item.productId,
    [t("categoryId")]: item.categoryId,
    [t("ProductName")]: item.productName,
    [t("productPrice")]: item.productPrice,
    [t("priceWithoutVAT")]: item.sellPriceWithoutVat,
    [t("VAT_AMT")]: item.vatAmount,
    [t("sellPrice")]: item.sellPrice
  }));

    //handle parent pagination
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
  
    const indexOfLastRow = currentPage * itemsPerPage;
    const indexOfFirstRow = indexOfLastRow - itemsPerPage;
    const currentProductsData = products.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(products.length / itemsPerPage);
  
    const handlePageChange = page => {
      setCurrentPage(page);
    };
  

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("productsView")}</h3>

      <div className="card m-3 p-4 gap-3 d-flex flex-column flex-sm-row">
        {/* Category Section */}
        <div className="d-flex flex-column flex-sm-row gap-3">
          <span className="mt-2">{t("اسم الصنف")}</span>
          <Dropdown
            value={selectedValue}
            options={categories}
            onChange={handleCategoryChange}
            optionLabel="categoryName"
            placeholder="Select a category"
            filter
            filterBy="categoryName"
          />
        </div>

        {/* Button Section */}
        <div className="targetBtn-container d-flex flex-column  gap-3">
          <Button
            label={t("search")}
            style={{
              border: "none",
              backgroundColor: "#007bff",
              color: "#fff"
            }}
            className="rounded-2 btn w-100 mb-2"
            onClick={() => handleButtonClick(selectedCategoryId)}
          />
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        {showData && (
          <div className="table-responsive p-2">
            {loading ? (
              <Loading />
            ) : currentProductsData && products.length > 0 ? (
              <Table striped hover responsive>
                <thead className="custom-likeCard-thead">
                  <tr>
                    <th>{t("productNumber")}</th>
                    <th>{t("categoryId")}</th>
                    <th>{t("ProductName")}</th>
                    <th>{t("productPrice")}</th>
                    <th>{t("priceWithoutVAT")}</th>
                    <th>{t("VAT_AMT")}</th>
                    <th>{t("sellPrice")}</th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {currentProductsData.map((order, index) => (
                    <tr key={index}>
                      <td width="100px" className="ml-4">
                        {order.productId}
                      </td>
                      <td width="100px" className="ml-4">
                        {order.categoryId}
                      </td>
                      <td width="100px" className="ml-4">
                        {order.productName}
                      </td>
                      <td width="100px" className="ml-4">
                        {order.productPrice}
                      </td>
                      <td width="100px" className="ml-4">
                        {order.sellPriceWithoutVat}
                      </td>
                      <td width="200px" className="ml-4">
                        {order.vatAmount}
                      </td>
                      <td width="100px" className="ml-4">
                        {order.sellPrice}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="no-products-message">{t("No available products")}</div>
              )}
              <CustomPagination
              className="col-sm-12"
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              indexOfLastRow={indexOfLastRow}
              reportData={products}
            />
          </div>
        )}
        {loading && showData && <Loading />}
      </div>
    </div>
  );
};

export default GetProducts;
