import { Toaster } from "react-hot-toast";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import Component from "./constants/Component";
import VenderContext from "./context/Store";
import "./style/App.scss";

function App() {
  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem("userToken");
    if (!token) {
      return <Navigate to="/admin/login" replace={true} />;
    } else {
      return <>{children}</>;
    }
  }

  const root = createHashRouter([
    {
      path: "/",
      element: <Component.Vendor />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoutes>
              <Component.Dashboard />
            </ProtectedRoutes>
          )
        },
        {
          path: "/contact",
          element: (
            <ProtectedRoutes>
              <Component.Contact />
            </ProtectedRoutes>
          )
        },
        {
          path: "home",
          element: (
            <ProtectedRoutes>
              <Component.Home />{" "}
            </ProtectedRoutes>
          )
        },

        { path: "profile", element: <Component.Profile /> },
        {
          path: "*",
          element: (
            <ProtectedRoutes>
              <Component.Error />
            </ProtectedRoutes>
          )
        },

        //LikeCard
        {
          path: "/LikeCard/checkBalance",
          element: (
            <ProtectedRoutes>
              <Component.CheckBalance />
            </ProtectedRoutes>
          )
        },
        {
          path: "/LikeCard/viewCategories",
          element: (
            <ProtectedRoutes>
              <Component.ViewCategories />
            </ProtectedRoutes>
          )
        },
        {
          path: "/LikeCard/get-orders",
          element: (
            <ProtectedRoutes>
              <Component.GetOrders />
            </ProtectedRoutes>
          )
        },
        {
          path: "/LikeCard/get-products",
          element: (
            <ProtectedRoutes>
              <Component.GetProducts />
            </ProtectedRoutes>
          )
        },
        {
          path: "/LikeCard/get-products-byId",
          element: (
            <ProtectedRoutes>
              <Component.GetProductsById />
            </ProtectedRoutes>
          )
        },
        //oneCard
        {
          path: "/oneCard/check-balance",
          element: (
            <ProtectedRoutes>
              <Component.CheckBalanceOneCard />
            </ProtectedRoutes>
          )
        },
        {
          path: "/oneCard/view-products",
          element: (
            <ProtectedRoutes>
              <Component.ViewProductsOneCard />
            </ProtectedRoutes>
          )
        },

        //purchase
        {
          path: "/purchase/done-purchase",
          element: (
            <ProtectedRoutes>
              <Component.DonePurchase />
            </ProtectedRoutes>
          )
        },
        {
          path: "/purchase/running-purchase",
          element: (
            <ProtectedRoutes>
              <Component.runningPurchase />
            </ProtectedRoutes>
          )
        },
        //flutter order controller
        {
          path: "/FlutterOrderController/get-all-balance",
          element: (
            <ProtectedRoutes>
              <Component.GetAllBalance />
            </ProtectedRoutes>
          )
        },
        //Statments
        {
          path: "/statments/Current-inventory-balance",
          element: (
            <ProtectedRoutes>
              <Component.CurrentInventoryBalance />
            </ProtectedRoutes>
          )
        },
        {
          path: "/users/view-users",
          element: (
            <ProtectedRoutes>
              <Component.ViewUsers />
            </ProtectedRoutes>
          )
        },
        {
          path: "/all-products",
          element: (
            <ProtectedRoutes>
              <Component.AllProducts />
            </ProtectedRoutes>
          )
        }
      ]
    },

    {
      path: "/admin/",
      element: <Component.Auth />,
      children: [{ path: "login", element: <Component.Login /> }]
    }
  ]);
  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: " Arial, Helvetica, sans-serif",
            textTransform: "capitalize",
            zIndex: "9999",
            // background: '#000',
            // color: '#fff',
            borderRadius: "10px",
            boxShadow: "10px 10px 10px rgba(188, 188, 188, 0.16)",
            background: "#fff",
            color: "#000"
          }
        }}
        containerStyle={{
          bottom: 50
        }}
      />

      <VenderContext>
        <RouterProvider router={root} />
      </VenderContext>
    </div>
  );
}

export default App;
