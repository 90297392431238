import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { BASE_URL } from "../../../context/Enviroment";
import Loading from "../../../Components/Loading/Loading";
import { InputText } from "primereact/inputtext";
import "./ViewProductsOneCard.scss";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../Components/Paginator/Paginator";

const ViewProductsOneCard = () => {
  const { t } = useTranslation();

  const [productCode, setProductCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("NameEn");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/OneCard/GetProducts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      const data = await response.json();
      setProductDetails(data.DateSet.ProductList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product details:", error.message);
    } finally {
      setLoading(false);
    }
  };
  // const fetchData = async () => {
  //   setLoading(true);

  //   try {
  //     const userToken = localStorage.getItem("userToken");

  //     const response = await fetch(`${BASE_URL}/OneCard/GetProductDetails?Id=${productCode}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userToken}`
  //       }
  //     });

  //     const data = await response.json();
  //     setProductDetails(data);
  //   } catch (error) {
  //     console.error("Error fetching product details:", error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // handle excel exporter
  const columnsToExport = productDetails
    ? productDetails.map(item => ({
        [t("ProductCode")]: item.ProductCode,
        [t("ProductName")]: item.NameAr,
        // [t("productPrice")]: item.ProductPrice,
        [t("TotalPosPrice")]: item.PosPrice.toFixed(4),
        [t("Available")]: item.Available ? "Yes" : "No",
        [t("VAT_AMT")]: item.VatValue.toFixed(4)
      }))
    : [];

  const extractFirstFourWords = text => {
    if (!text) return "";
    const words = text.split(" ");
    const firstFourWords = words.slice(0, 8).join(" ");
    return firstFourWords;
  };

  //numbers format
  const formatNumber = number => {
    const formattedNumber = Number(number).toFixed(4);
    const trimmedNumber = parseFloat(formattedNumber);
    return trimmedNumber.toString();
  };

  //handled search
  const handleSearch = () => {
    if (!productDetails || !searchTerm) {
      return productDetails || [];
    }

    const filteredProducts = productDetails.filter(product => {
      return product.NameEn && product.NameEn.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return filteredProducts;
  };

  //handle pagination
  const [currentPageOrders, setCurrentPageOrders] = useState(1);
  const [itemsPerPageOrders] = useState(10);

  const indexOfLastItemOrders = currentPageOrders * itemsPerPageOrders;
  const indexOfFirstItemOrders = indexOfLastItemOrders - itemsPerPageOrders;
  const currentItemsOrders =
    productDetails && Array.isArray(productDetails) && productDetails.length > 0
      ? productDetails.slice(indexOfFirstItemOrders, indexOfLastItemOrders)
      : [];

  const totalRecordsOrders = productDetails && Array.isArray(productDetails) ? productDetails.length : 0;
  const totalPagesOrders = Math.ceil(totalRecordsOrders / itemsPerPageOrders);

  const handlePageChangeOrders = newPage => {
    setCurrentPageOrders(newPage);
  };
  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("productsView")}</h3>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <InputText
            className="tergetReport-dropdown p-2 w-50"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder={t("search")}
          />
          <ExcelExporter data={productDetails || []} fileName="report_data" disabled={!productDetails} />
        </div>
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Table striped hover>
                <thead className="custom-oneCard-thead">
                  <tr>
                    <th style={{ width: "16.67%" }}>{t("ProductCode")}</th>
                    <th style={{ width: "16.67%" }}>{t("ProductName")}</th>
                    <th style={{ width: "16.67%" }}>{t("TotalPosPrice")}</th>
                    <th style={{ width: "16.67%" }}>Available</th>
                    <th style={{ width: "16.67%" }}>{t("VAT_AMT")}</th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {(searchTerm === "" ? currentItemsOrders || [] : handleSearch()).map(product => (
                    <tr key={product.ProductCode}>
                      <td>{product.ProductCode}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{product.NameEn}</td>
                      <td>{formatNumber(product.PosPrice)}</td>
                      <td>{product.Available ? "Yes" : "No"}</td>
                      <td>{formatNumber(product.VatValue)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={currentPageOrders}
                totalPages={totalPagesOrders}
                handlePageChange={handlePageChangeOrders}
                indexOfLastRow={indexOfLastItemOrders}
                reportData={productDetails}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewProductsOneCard;
