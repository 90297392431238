import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.scss";
import { VendersContext } from "../../context/Store";
import CountUp from "react-countup";
import oneCard from "../../assets/Images/oneCArdLogo.png"
import likeCard from "../../assets/Images/like-card.png"
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../context/Enviroment";

function Dashboard() {
  let { isLang } = useContext(VendersContext);
  const { t } = useTranslation();
  const [oneCardBalance, setOneCardBalance] = useState("")
  const [likeCardBalance, setLikeCardBalance] = useState("")


useEffect(() => {
    GetlikecardBalance()
    GetoneCardBalance()
}, [])

  const GetlikecardBalance = async () => {
    const userToken = localStorage.getItem("userToken");

    try {
      const response = await fetch(`${BASE_URL}/LikeCard/CheckBalance`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setLikeCardBalance(data.DateSet.balance);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  const GetoneCardBalance = async () => {
    const userToken = localStorage.getItem("userToken");

    try {
      const response = await fetch(`${BASE_URL}/OneCard/CheckBalance`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setOneCardBalance(data.POSCheckBalanceResponse.balance);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  return (
    <>
     <div className="container">
  <div className="row">
    <div className="col-md-3">
      <div className="grid d-flex justify-content-around dashboard-box card-shadow w-100 rounded-4">
      <img width="70px" src={oneCard}/>
        <div className="media-body text-right d-flex flex-column">
          <span className="h5">{t("balance")}:</span>
          {/* <CountUp start={0} end={parseFloat(oneCardBalance)} duration={2} separator="," className="mb-1 h2" /> */}
          <span className="mb-1 h2">{oneCardBalance}</span>

          <span className="h4">SAR</span>

        </div>
      </div>
    </div>
    <div className="col-md-3">
      <div className="grid d-flex justify-content-around dashboard-box card-shadow w-100 rounded-4">
      <img width="80px" src={likeCard}/>
        <div className="media-body text-right d-flex flex-column">
          <span className="h5">{t("balance")}:</span>
          <span className="mb-1 h2">{likeCardBalance}</span>
          
          {/* <InputNumber value={likeCardBalance} mode="currency" currency="SAR" locale="en-US" readOnly inputStyle={{ fontSize: '1.5rem' }} /> */}

          <span className="h4">SAR</span>
        </div>
      </div>
    </div>
   
  

  </div>
</div>

    </>
  );
}

export default Dashboard;
