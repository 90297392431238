import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../context/Enviroment";
import Loading from "../../../Components/Loading/Loading";
import { InputText } from "primereact/inputtext";
import "./ViewUsers.scss";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../Components/Paginator/Paginator";
import Swal from "sweetalert2";
import { Dialog } from "primereact/dialog";

const ViewUsers = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [language, setLanguage] = useState("en");
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [fullNmae, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  useEffect(() => {
    fetchData();
    const storedLanguage = localStorage.getItem("langChange");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/ApplicationUser/GetUsers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      const data = await response.json();
      setProductDetails(data.DateSet.users);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //handled search
  const handleSearch = () => {
    if (!productDetails || !searchTerm) {
      return productDetails || [];
    }

    const filteredProducts = productDetails.filter(product => {
      return product.UserName && product.UserName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return filteredProducts;
  };

  //handle pagination
  const [currentPageOrders, setCurrentPageOrders] = useState(1);
  const [itemsPerPageOrders] = useState(10);

  const indexOfLastItemOrders = currentPageOrders * itemsPerPageOrders;
  const indexOfFirstItemOrders = indexOfLastItemOrders - itemsPerPageOrders;
  const currentItemsOrders =
    productDetails && Array.isArray(productDetails) && productDetails.length > 0
      ? productDetails.slice(indexOfFirstItemOrders, indexOfLastItemOrders)
      : [];

  const totalRecordsOrders = productDetails && Array.isArray(productDetails) ? productDetails.length : 0;
  const totalPagesOrders = Math.ceil(totalRecordsOrders / itemsPerPageOrders);

  const handlePageChangeOrders = newPage => {
    setCurrentPageOrders(newPage);
  };

  //handle onChange switch
  const handleToggle = async (userId, isActive) => {
    let message = "";
    let icon = "";

    if (isActive) {
      message = t("users.confirmationToggleStatusFalse");
      icon = "info";
    } else {
      message = t("users.confirmationToggleStatusTrue");
      icon = "warning";
    }

    const result = await Swal.fire({
      title: t("users.Confirmation"),
      text: message,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes"),
      cancelButtonText: t("discard")
    });

    if (result.isConfirmed) {
      try {
        const userToken = localStorage.getItem("userToken");

        const response = await fetch(`${BASE_URL}/ApplicationUser/ActivateDeactivateUser?userId=${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`
          }
        });

        if (response.ok) {
          fetchData();
        } else {
          console.error("Failed to toggle user status");
        }
      } catch (error) {
        console.error("Error toggling user status:", error.message);
      }
    }
  };

  const showDialog = userId => {
    setSelectedUserId(userId);
    setVisible(true);
    // Reset password fields
    setPassword("");
    setConfirmPassword("");
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const handlePasswordChange = async () => {
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Password mismatch",
        text: "Please make sure the passwords match"
      });
      return;
    }

    try {
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/ApplicationUser/ChangePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify({
          userId: selectedUserId,
          password: password
        })
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Password Changed Successfully",
          text: "Your password has been changed successfully."
        });
        hideDialog();
      } else {
        console.error("Failed to change password");
      }
    } catch (error) {
      console.error("Error changing password:", error.message);
    }
  };

  const handleAddUser = async () => {
    try {
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/ApplicationUser/Register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify({
          userName: fullNmae,
          email: email,
          password: password,
          confirmPassword: password,
          firstName: fullNmae,
          role: userRole === "admin" ? "admin" : "SuperAdmin"
        })
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "User Added Successfully",
          text: "New user has been added successfully."
        });
        setAddUserVisible(false);
        fetchData();
      } else {
        console.error("Failed to add user");
      }
    } catch (error) {
      console.error("Error adding user:", error.message);
    }
  };

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("users.users")}</h3>
      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          {/* <InputText
            className="tergetReport-dropdown p-2 w-50"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder={t("search")}
          /> */}
          <ExcelExporter data={productDetails || []} fileName="report_data" disabled={!productDetails} />
          <div className="targetBtn-container d-flex flex-column  gap-3">
            <Button
              style={{
                border: "none",
                backgroundColor: "#007bff",
                color: "#fff"
              }}
              onClick={() => setAddUserVisible(true)}
              className="rounded-2 btn w-100 "
            >
              {t("users.newUser")}
            </Button>
          </div>
        </div>
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Table striped hover>
                <thead className="custom-oneCard-thead">
                  <tr>
                    <th style={{ width: "16.67%" }}>{t("users.userName")}</th>
                    <th style={{ width: "16.67%" }}>{t("users.Email")}</th>
                    <th style={{ width: "16.67%" }}>{t("users.PhoneNumber")}</th>
                    <th style={{ width: "16.67%" }}>{t("users.company")}</th>
                    <th style={{ width: "16.67%" }}>{t("users.status")}</th>
                    <th style={{ width: "16.67%" }} />
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {(searchTerm === "" ? currentItemsOrders || [] : handleSearch()).map(product => (
                    <tr key={product.ProductCode}>
                      <td style={{ whiteSpace: "pre-wrap" }}>{product.UserName}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{product.Email}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{product.PhoneNumber}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{product.company}</td>
                      <td>
                        <div className="form-check form-switch d-flex m-0 activitionSwitch-contsiner">
                          <input
                            className="form-check-input activition-user"
                            type="checkbox"
                            id={`flexSwitchCheck${product.Id}`}
                            checked={!product.IsActive}
                            onChange={() => handleToggle(product.Id, !product.IsActive)}
                          />
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          className="rounded-5 justify-content-center"
                          onClick={() => showDialog(product.Id)}
                        >
                          <i className="pi pi-pencil" /> {t("changePassword")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={currentPageOrders}
                totalPages={totalPagesOrders}
                handlePageChange={handlePageChangeOrders}
                indexOfLastRow={indexOfLastItemOrders}
                reportData={productDetails}
              />
            </>
          )}
        </div>
      </div>
      <Dialog
        header={t("changePassword")}
        className={language === "ar" ? "rtl-dialog" : "ltr-dialog"}
        visible={visible}
        onHide={hideDialog}
        modal
        style={{ width: "30vw" }}
      >
        <div className="p-fluid">
          <div className="p-field mb-2">
            <label htmlFor="password">{t("password")}</label>
            <InputText id="password" value={password} onChange={e => setPassword(e.target.value)} type="password" required />
          </div>
          <div className="p-field">
            <label htmlFor="confirmPassword">{t("ConfirmPassword")}</label>
            <InputText
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              type="password"
              required
            />
          </div>
        </div>
        <div className="p-dialog-footer mt-3 d-flex gap-2 align-items-center">
          <Button variant="primary" onClick={handlePasswordChange}>
            {t("users.update")}
          </Button>
          <Button variant="secondary" onClick={hideDialog}>
            {t("discard")}
          </Button>
        </div>
      </Dialog>

      {/* Add User Dialog */}
      <Dialog
        header={t("users.addUser")}
        className={language === "ar" ? "rtl-dialog" : "ltr-dialog"}
        visible={addUserVisible}
        onHide={() => setAddUserVisible(false)} // Close the add user dialog
        modal
        style={{ width: "50vw" }}
      >
        <div className="d-flex flex-row gap-4 w-100">
          <div className="d-flex flex-column gap-2 mb-2 w-50">
            <label className="" htmlFor="fullNmae">
              {t("users.fullNmae")}
            </label>
            <InputText
              className="w=100"
              id="fullNmae"
              value={fullNmae}
              onChange={e => setFullName(e.target.value)}
              type="text"
              required
            />
          </div>
          <div className="d-flex flex-column gap-2 mb-2 w-50">
            <label htmlFor="email">{t("email")}</label>
            <InputText id="email" value={email} onChange={e => setEmail(e.target.value)} type="email" required />
          </div>
        </div>
        <div className="d-flex flex-row gap-4 w-100">
          <div className="d-flex flex-column gap-2 mb-2 w-50">
            <label htmlFor="password">{t("password")}</label>
            <InputText id="password" value={password} onChange={e => setPassword(e.target.value)} type="password" required />
          </div>
          <div className="d-flex flex-column gap-2 mb-2 w-50">
            <label htmlFor="confirmPassword">{t("ConfirmPassword")}</label>
            <InputText
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              type="password"
              required
            />
          </div>
        </div>
        {/* Radio buttons for selecting user role */}
        <div className="d-flex flex-row gap-4 w-100 mt-4">
          <div className="  mb-2 w-50 d-flex flex-row gap-4">
            <div className="form-check ">
              <input
                className="form-check-input"
                type="radio"
                name="role"
                id="adminRole"
                value="admin"
                checked={userRole === "admin"}
                onChange={() => setUserRole("admin")}
              />
              <label className="form-check-label" htmlFor="adminRole">
                Admin
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="role"
                id="superAdminRole"
                value="super_admin"
                checked={userRole === "super_admin"}
                onChange={() => setUserRole("super_admin")}
              />
              <label className="form-check-label" htmlFor="superAdminRole">
                Super Admin
              </label>
            </div>
          </div>
        </div>
        <div className="p-dialog-footer mt-3 d-flex gap-2 align-items-center">
          <Button variant="primary" onClick={handleAddUser}>
            {t("users.add")}
          </Button>
          <Button variant="secondary" onClick={() => setAddUserVisible(false)}>
            {t("discard")}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ViewUsers;
