import React, { useEffect, useState } from "react";
import Loading from "../../../Components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Table } from "react-bootstrap";
import { BASE_URL } from "../../../context/Enviroment";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../Components/Paginator/Paginator";
import { format } from "date-fns";

export default function DonePurchase() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [donePurchase, setDonePurchase] = useState(null);

  const fetchData = async () => {
    const userToken = localStorage.getItem("userToken");

    setLoading(true);

    try {
      const requestBody = {
        fromDate: startDate ? startDate.toISOString() : null,
        toDate: endDate ? endDate.toISOString() : null,
        pageNo: 1,
        pageSize: 1000
      };

      const response = await fetch(`${BASE_URL}/Purchase/GetDonePurchase`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setDonePurchase(data.DateSet);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchData();
  }, []);

  //format date and time
  const formatDate = dateString => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  const formatTime = dateString => {
    const formattedTime = format(new Date(dateString), "h:mm a");
    return formattedTime;
  };

  //handle pagination


  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    donePurchase && donePurchase.Items && donePurchase.Items.length > 0
      ? donePurchase.Items.slice(indexOfFirstItem, indexOfLastItem)
      : [];
  const totalRecords = donePurchase && donePurchase.Items ? donePurchase.Items.length : 0;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const columnsToExport = donePurchase?.Items.map(item => ({
    [t("order_no")]: item.order_no,
    [t("order_count")]: item.order_count,
    [t("done_qty")]: item.done_qty,
    [t("class_no")]: item.class_no,
    [t("status")]: item.status,
    [t("class_price")]: item.class_price,
    [t("class_cost")]: item.class_cost,
    [t("sellPriclass_namece")]: item.class_name,
    [t("request_code")]: item.request_code,
    [t("edit_date")]: item.edit_date,
    [t("edit_user")]: item.edit_user,
    [t("sellPriProviderNamece")]: item.ProviderName
  }));
  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("ordersView")}</h3>
      <div className="card mb-4 custom-card  m-3 d-flex flex-row p-4">
        <div className="row">
          <div className="col-md-9 col-sm-12 d-flex flex-row gap-4">
            {/* start date input  */}
            <div className="custom-dropdown d-flex flex-column mb-3 w-100">
              <span className="h6">{t("from")}: </span>
              <div className="p-input-icon-left">
                <i className="pi pi-calendar calendar-icon text-main" />
                <Calendar
                  className="tergetReport-calendar w-100"
                  dateFormat="dd/mm/yy"
                  placeholder={t("choose")}
                  value={startDate}
                  onChange={e => setStartDate(e.value)}
                />
              </div>
            </div>
            {/* end date input  */}
            <div className="custom-dropdown d-flex flex-column mb-3 w-100">
              <span className="ml-2 h6">{t("to")}: </span>
              <div className="p-input-icon-left">
                <i className="pi pi-calendar calendar-icon text-main" />
                <Calendar
                  className="tergetReport-calendar w-100"
                  dateFormat="dd/mm/yyyy"
                  placeholder={t("choose")}
                  value={endDate}
                  onChange={e => setEndDate(e.value)}
                />
              </div>
            </div>
          </div>
          {/* search btn */}
          <div className="col-md-3 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-4 gap-3">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={fetchData}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : donePurchase && donePurchase.Items && donePurchase.Items.length > 0 ? (
            <div>
              <Table striped hover>
                <thead className="custom-likeCard-thead">
                  <tr>
                    <th>Order No</th>
                    <th>Order Count</th>
                    <th>Done Quantity</th>
                    <th>Class No</th>
                    <th>Status</th>
                    <th>Class Price</th>
                    <th>Class Cost</th>
                    <th>Service ID</th>
                    <th>Class Name</th>
                    <th>Request Code</th>
                    <th>Edit Date</th>
                    <th>Edit Time</th>
                    <th>Edit User</th>
                    <th>Provider Name</th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {currentItems.map(item => (
                    <tr key={item.order_no}>
                      <td>{item.order_no}</td>
                      <td>{item.order_count}</td>
                      <td>{item.done_qty}</td>
                      <td>{item.class_no}</td>
                      <td>{item.status ? "متاح" : "غير متاح"}</td>
                      <td>{item.class_price}</td>
                      <td>{item.class_cost}</td>
                      <td>{item.service_id}</td>
                      <td>{item.class_name}</td>
                      <td>{item.request_code}</td>
                      <td>{formatDate(item.edit_date)}</td>
                      <td dir="ltr">{formatTime(item.edit_date)}</td>
                      <td>{item.edit_user}</td>
                      <td>{item.ProviderName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastItem}
                reportData={donePurchase.Items}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
