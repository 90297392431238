import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { BASE_URL } from "../../../context/Enviroment";
import Loading from "../../../Components/Loading/Loading";
import { InputText } from "primereact/inputtext";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";

const GetProducts = () => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [productCode, setProductCode] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    fetch(`${BASE_URL}/LikeCard/GetCategories`)
      .then(response => response.json())
      .then(data => {
        const flattenedCategories = flattenCategories(data?.DateSet?.data || []);
        setCategories(flattenedCategories);
      })
      .catch(error => console.error("Error fetching categories:", error));
  };

  const flattenCategories = categories => {
    return categories.reduce((flattened, category) => {
      flattened.push(category);
      if (category.childs && category.childs.length > 0) {
        flattened = flattened.concat(category.childs.map(child => ({ ...child, isChild: true })));
      }
      return flattened;
    }, []);
  };

  const getProductsById = async () => {
    setLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/LikeCard/GetProductById?productId=${productCode}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      const data = await response.json();
      setProductDetails(data.DateSet.data);
      console.log(data.DateSet.data);
    } catch (error) {
      console.error("Error fetching product details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //handle excel exporter
  const columnsToExport = products.map(item => ({
    [t("سعر المنتج")]: item.ProductPrice,
    [t("Available")]: item.Available ? "متاح" : "غير متاح"
  }));

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("productsView")}</h3>

      <div className="card m-3 p-4 gap-3 d-flex flex-column flex-sm-row">
        <div className="d-flex flex-column flex-sm-row gap-3">
          <span className="mt-2">{t("productNumber")}: </span>
          <InputText
            className="tergetReport-dropdown p-2 w-100"
            value={productCode}
            onChange={e => setProductCode(e.target.value)}
            placeholder={t("search")}
          />
        </div>
        <div className="targetBtn-container d-flex flex-column flex-sm-row gap-3 mt-3 mt-sm-0">
          <Button
            label={t("search")}
            style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
            className="rounded-2 btn w-100 mb-2"
            onClick={() => getProductsById(productCode)}
          />
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>

        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : productDetails && productDetails.length > 0 ? (
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("سعر المنتج")}</th>
                  <th>{t("Available ")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {productDetails?.map((product, index) => (
                  <tr key={index}>
                    <td width="100px" className="ml-4">
                      {product.ProductPrice}
                    </td>
                    <td width="100px" className="ml-4">
                      {product.Available ? "متاح" : "غير متاح"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}{" "}
        </div>
      </div>
    </div>
  );
};

export default GetProducts;
