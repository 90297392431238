import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Img from "../../assets/Img";
import { VendersContext } from "../../context/Store";
import "./Sidebar.scss";
import routes from "./route.js";
import SidebarMenu from "./SidebarMenu";
import { FaBars } from "react-icons/fa";

const Sidebar = ({ children }) => {
  let { isLang, isOpen, toggle, setIsOpen } = useContext(VendersContext);
  const [filteredRoutes, setFilteredRoutes] = useState([]);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: { duration: 0.4, when: "afterChildren" }
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.3,
        when: "beforeChildren"
      }
    }
  };

  // Retrieve user role from local storage
  const userRole = localStorage.getItem("role");
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedRoutes = routes.map(route => {
          if (route.subRoutes) {
            const filteredSubRoutes = route.subRoutes.filter(sub => {
              const hasSubUserRole = sub.adminRole && sub.adminRole.includes(userRole);
              return hasSubUserRole;
            });
            const userRoleSubRoutes = route.subRoutes.filter(sub => sub.adminRole && sub.adminRole.includes(userRole));
            const finalSubRoutes = userRoleSubRoutes.length > 0 ? userRoleSubRoutes : filteredSubRoutes;
            return finalSubRoutes.length > 0 ? { ...route, subRoutes: finalSubRoutes } : null;
          } else if (!route.adminRole || route.adminRole.includes(userRole)) {
            // Include the main route if it doesn't have an adminRole or the user's role is included
            return route;
          }
          return null;
        }).filter(Boolean);
        setFilteredRoutes(updatedRoutes);
      } catch (error) {
        console.error("Error fetching routes:", error);
      }
    };
    fetchData();
  }, [userRole]);

  return (
    <div className="main-container " dir={isLang === "ar" ? "rtl" : "ltr"}>
      <motion.div
        dir={isLang === "ar" ? "rtl" : "ltr"}
        animate={{
          width: isOpen ? "250px" : "50px",
          background: "#000",
          transition: {
            duration: 0.7,
            type: "spring",
            damping: 10
          }
        }}
        className={`sidebar  `}
      >
        <div className="side   " style={{ [isLang === "ar" ? "right" : "left"]: 0 }}>
          <div className="top_section  ">
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo mt-3"
                  key={1}
                >
                  <Link to={"/"} style={{ cursor: "pointer" }}>
                    <img loading="lazy" src={Img.logo} alt="logo" width={150} />
                  </Link>
                </motion.div>
              )}
              <div className="bars mt-1">
                <FaBars onClick={toggle} size={20} />
              </div>
            </AnimatePresence>
          </div>
          <section className={isLang === "ar" ? "routes routesAr" : "routes"}>
            {filteredRoutes.map((root, i) => {
              if (root.subRoutes) {
                return (
                  <SidebarMenu
                    key={i}
                    setIsOpen={setIsOpen}
                    route={root}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    open={isOpen}
                    isLang={isLang}
                  />
                );
              }
              // Render the main route
              return (
                <motion.div
                  key={i}
                  animate={{
                    transition: {
                      duration: 2,
                      damping: 10
                    }
                  }}
                >
                  <NavLink to={root.path} key={i} className="link">
                    <div className="icon" id={root.name} data-tooltip-content={isLang === "ar" ? root.nameAr : root.nameEn}>
                      {root.icon}
                    </div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {isLang === "ar" ? root.nameAr : root.nameEn}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                </motion.div>
              );
            })}
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default Sidebar;
