import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../context/Enviroment";
import CustomPagination from "../../Components/Paginator/Paginator";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import "./ViewCategories.scss";
import { InputText } from 'primereact/inputtext';

const ParentChildTable = () => {
  const [parentData, setParentData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);

  const [grandchildData, setGrandchildData] = useState([]);

  const [selectedParent, setSelectedParent] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentPageChild, setCurrentPageChild] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    const userToken = localStorage.getItem("userToken");
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/LikeCard/GetCategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setParentData(data.DateSet.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleParentRowClick = parentItem => {
    setSelectedParent(parentItem);
    setChildData(parentItem.childs);
    setSelectedChild(null);
    setGrandchildData([]);
    setCurrentPageChild(1);
    setCurrentPageGrandchild(1);
  };
  const handleChildRowClick = childItem => {
    setSelectedChild(childItem);
    setGrandchildData(childItem.childs);
    setCurrentPageGrandchild(1);
  };
  //handle parent pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastRow = currentPage * itemsPerPage;
  const indexOfFirstRow = indexOfLastRow - itemsPerPage;
  const currentParentData = parentData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(parentData.length / itemsPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle child pagination
  const indexOfLastRowChild = currentPageChild * itemsPerPage;
  const indexOfFirstRowChild = indexOfLastRowChild - itemsPerPage;
  const currentChildData = childData.slice(indexOfFirstRowChild, indexOfLastRowChild);
  const totalPagesChild = Math.ceil(childData.length / itemsPerPage);

  const handlePageChangeChild = page => {
    setCurrentPageChild(page);
  };
  //handle grandChild pagination
  const [currentPageGrandchild, setCurrentPageGrandchild] = useState(1);

  const indexOfLastRowGrandchild = currentPageGrandchild * itemsPerPage;
  const indexOfFirstRowGrandchild = indexOfLastRowGrandchild - itemsPerPage;
  const currentGrandchildData = grandchildData.slice(indexOfFirstRowGrandchild, indexOfLastRowGrandchild);
  const totalPagesGrandchild = Math.ceil(grandchildData.length / itemsPerPage);

  const handlePageChangeGrandchild = page => {
    setCurrentPageGrandchild(page);
  };

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("mainCategories")}</h3>

      <div className="card m-3 p-3">
      <InputText className="w-25 mx-2" type="text" placeholder={t("search")} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />

        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("categoryNumber")}</th>
                  <th>{t("categoryName")}</th>
                  <th>{t("photo")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {parentData
                  .filter(item => item.categoryName.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map(parentItem => (
                    <tr key={parentItem.id} onClick={() => handleParentRowClick(parentItem)}>
                      <td>{parentItem.id}</td>
                      <td>{parentItem.categoryName}</td>
                      <td>
                        <img
                          width="70px"
                          height="50px"
                          className="categoy-img img-fluid"
                          src={parentItem.amazonImage}
                          alt={parentItem.categoryName}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          <div className="col-sm-12">
            <CustomPagination
              className="col-sm-12"
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              indexOfLastRow={indexOfLastRow}
              reportData={parentData}
            />
          </div>
        </div>
      </div>

      <div className="card m-3">
        {selectedParent && currentChildData.length > 0 && (
          <div>
            <h3 className="text-main m-3 report-head">{t("childCategories")}</h3>
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("categoryNumber")}</th>
                  <th>{t("categoryName")}</th>
                  <th>{t("photo")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {currentChildData.map(childItem => (
                  <tr key={childItem.id} onClick={() => handleChildRowClick(childItem)}>
                    <td>{childItem.id}</td>
                    <td>{childItem.categoryName}</td>
                    <td>
                      <img
                        width="70px"
                        height="50px"
                        className="categoy-img img-fluid"
                        src={childItem.amazonImage}
                        alt={childItem.categoryName}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <CustomPagination
              currentPage={currentPageChild}
              totalPages={totalPagesChild}
              handlePageChange={handlePageChangeChild}
              indexOfLastRow={indexOfLastRowChild}
              reportData={childData}
            />
          </div>
        )}
      </div>
      <div className="card m-3">
        {selectedChild && currentGrandchildData.length > 0 && (
          <div>
            <h3 className="text-main m-3 report-head">{t("childCategories")}</h3>
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("categoryNumber")}</th>
                  <th>{t("categoryName")}</th>
                  <th>{t("photo")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {currentGrandchildData.map(grandchildItem => (
                  <tr key={grandchildItem.id}>
                    <td>{grandchildItem.id}</td>
                    <td>{grandchildItem.categoryName}</td>
                    <td>
                      <img
                        width="70px"
                        height="50px"
                        className="categoy-img img-fluid"
                        src={grandchildItem.amazonImage}
                        alt={grandchildItem.categoryName}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <CustomPagination
              currentPage={currentPageGrandchild}
              totalPages={totalPagesGrandchild}
              handlePageChange={handlePageChangeGrandchild}
              indexOfLastRow={indexOfLastRowGrandchild}
              reportData={grandchildData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentChildTable;
