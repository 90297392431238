import React from "react";
import * as XLSX from "xlsx";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import "./ExcelExporter.scss";

const ExcelExporter = ({ data, fileName }) => {
  const { t, i18n } = useTranslation();
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <>
    <Button type="button" label={t("exportFile")} className="rounded-3 excel-button" style={{ border: "none" }} icon="pi pi-file-excel" severity="success" rounded onClick={exportToExcel} data-pr-tooltip="XLS" />
    </>
  );
};

export default ExcelExporter;
