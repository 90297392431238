import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../context/Enviroment";
// Validate Form and handle Login
export const handelLogin = async (values, setLoadEmail, navigate) => {
  const url = `${BASE_URL}/ApplicationUser/Login`;

  try {
    setLoadEmail(true);
    const { data } = await axios.post(url, values);
    console.log(data);
    if (data.ResponseCode === 1) {
      toast.success("Success");
      console.log("success");
      navigate("/");
      localStorage.setItem("userToken", data.DateSet.Token);
      localStorage.setItem("userName", data.DateSet.UserName);
      localStorage.setItem("role", data.DateSet.Role);

      setLoadEmail(false);
    } else if (data.ResponseCode === 2) {
      console.log("failed");
      setLoadEmail(false);
    } else {
      setLoadEmail(false);
    }
  } catch (error) {
    console.error(error.response.data);
    if (error.response.data.StatusCode === "Error") {
      toast.error(error.response.data.Message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    setLoadEmail(false);
  }
};
