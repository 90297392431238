import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../../Components/Loading/Loading";
import { BASE_URL } from "../../../context/Enviroment";

const CheckBalanceOneCard = () => {
  const [balanceData, setBalanceData] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const userToken = localStorage.getItem("userToken");
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/OneCard/CheckBalance`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setBalanceData(data.POSCheckBalanceResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">{t("viewBalance")} OneCard</h3>

      <div className="card m-3">
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <Table striped hover>
              <thead className="custom-likeCard-thead">
                <tr>
                  <th>{t("balance")}</th>
                  <th>{t("currency")}</th>
                </tr>
              </thead>
              <tbody className="text-center transactionlogs-table">
                {balanceData && (
                  <tr>
                    <td width="100px" className="ml-4">
                      {balanceData.balance}
                    </td>
                    <td width="100px" className="ml-4">
                      {balanceData.currency}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckBalanceOneCard;
