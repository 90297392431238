import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../../Components/Loading/Loading";
import { BASE_URL } from "../../../context/Enviroment";

const RunningPurchase = () => {
  const [runningPurchase, setRunningPurchase] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const userToken = localStorage.getItem("userToken");
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/Purchase/GetRunningPurchase`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setRunningPurchase(data.DateSet);
      console.log(data.DateSet);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <h3 className="text-main m-3 report-head">Running Purchase</h3>

      <div className="card m-3">
        <div className="table-responsive p-2">
          {loading ? (
            <Loading />
          ) : (
            <Table striped hover>
            <thead className="custom-likeCard-thead">
              <tr>
                <th>Order No</th>
                <th>Order Count</th>
                <th>Done Quantity</th>
                <th>Class No</th>
                <th>Status</th>
                <th>Class Price</th>
                <th>Class Cost</th>
                <th>Service ID</th>
                <th>Class Name</th>
                <th>Request Code</th>
                <th>Edit Date</th>
                <th>Edit User</th>
                <th>Provider Name</th>
              </tr>
            </thead>
            <tbody className="text-center transactionlogs-table">
              {runningPurchase && runningPurchase.map(item => (
                <tr key={item.order_no}>
                  <td>{item.order_no}</td>
                  <td>{item.order_count}</td>
                  <td>{item.done_qty}</td>
                  <td>{item.class_no}</td>
                  <td>{item.status}</td>
                  <td>{item.class_price}</td>
                  <td>{item.class_cost}</td>
                  <td>{item.service_id}</td>
                  <td>{item.class_name}</td>
                  <td>{item.request_code}</td>
                  <td>{item.edit_date}</td>
                  <td>{item.edit_user}</td>
                  <td>{item.ProviderName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          
          )}
        </div>
      </div>
    </div>
  );
};

export default RunningPurchase;
