import LogoSvg from "../../assets/svg/LogoSvg";
import { BiCartAlt, BiClipboard, BiCreditCardFront, BiLinkExternal } from "react-icons/bi";

const routes = [
  {
    path: "",
    nameEn: "Dashboard",
    nameAr: "الصفحة الرئيسية",
    icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
    adminRole: ["SuperAdmin", "Admin"]
  },
  {
    path: "/LikeCard",
    nameEn: "LikeCard",
    nameAr: "LikeCard",
    icon: <BiCreditCardFront className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/LikeCard/checkBalance",
        nameEn: "Check Balance",
        nameAr: "فحص الرصيد",
        adminRole: ["SuperAdmin", "Admin"]
      },

      {
        path: "/LikeCard/viewCategories",
        nameEn: "View Categories",
        nameAr: " عرض الأقسام",
        adminRole: ["SuperAdmin", "Admin"]
      },
      {
        path: "/LikeCard/get-orders",
        nameEn: "Get Orders",
        nameAr: " عرض الطلبات",
        adminRole: ["SuperAdmin", "Admin"]
      },
      {
        path: "/LikeCard/get-products",
        nameEn: "Get Orders By Name",
        nameAr: " عرض المنتجات بالاسم ",
        adminRole: ["SuperAdmin", "Admin"]
      },
      {
        path: "/LikeCard/get-products-byId",
        nameEn: "Get Orders By Id",
        nameAr: " عرض المنتجات بالرقم ",
        adminRole: ["SuperAdmin", "Admin"]
      }
    ]
  },
  {
    path: "/OneCard",
    nameEn: "OneCard",
    nameAr: "OneCard",
    icon: <BiCreditCardFront className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/oneCard/check-balance",
        nameEn: "Check Balance",
        nameAr: "فحص الرصيد",
        adminRole: ["SuperAdmin", "Admin"]
      },
      {
        path: "/oneCard/view-products",
        nameEn: "Check Balance",
        nameAr: " عرض المنتجات",
        adminRole: ["SuperAdmin", "Admin"]
      }
    ]
  },
  {
    path: "/Purchase",
    nameEn: "Purchase",
    nameAr: "Purchase",
    icon: <BiCartAlt className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/purchase/done-purchase",
        nameEn: "Done Purchase",
        nameAr: " Done Purchase ",
        adminRole: ["SuperAdmin", "Admin"]
      },
      {
        path: "/purchase/running-purchase",
        nameEn: "Done Purchase",
        nameAr: " Running Purchase ",
        adminRole: ["SuperAdmin", "Admin"]
      }
    ]
  },

  {
    path: "/FlutterOrderController",
    nameEn: "OrderController",
    nameAr: "OrderController",
    icon: <BiLinkExternal className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/FlutterOrderController/get-all-balance",
        nameEn: "All Balance ",
        nameAr: " اجمالي الرصيد ",
        adminRole: ["SuperAdmin", "Admin"]
      }
    ]
  },

  {
    path: "/statments",
    nameEn: "statments",
    nameAr: "الكشوفات",
    icon: <BiClipboard className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/statments/Current-inventory-balance",
        nameEn: " Current inventory balance ",
        nameAr: " رصيد المحزون الحالي ",
        adminRole: ["SuperAdmin", "Admin"]
      }
    ]
  },
  {
    path: "/users",
    nameEn: "users",
    nameAr: "العملاء",
    icon: <BiClipboard className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/users/view-users",
        nameEn: " View Users ",
        nameAr: " عرض العملاء ",
        adminRole: ["SuperAdmin"]
      }
      
    ]
  },
  {
    path: "all-products",
    nameEn: "All Products",
    nameAr: "المنتجات",
    icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
    adminRole: ["SuperAdmin", "Admin"]
  },
];

export default routes;
