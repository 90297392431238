import Vendor from "../Layout/Vendor";
import Navber from "./../Components/Navber/Navber";
import Error from "./../Components/NotFound/Error";
import Sildebar from "./../Components/Sidebar/Sildebar";
import Auth from "./../Layout/Auth";
import Contact from "./../Pages/Contact/Contact";
import Dashboard from "./../Pages/Dashbord/Dashbord";
import Login from "./../Pages/auth/Login/Login";
import Home from "./../Pages/Home/Home";
import Profile from "../Components/Profile/Profile";
import ViewCategories from "../Pages/LikeCard/ViewCategories";
import CheckBalance from "../Pages/LikeCard/CheckBalance";
import GetOrders from "../Pages/LikeCard/GetOrders/GetOrders";
import GetProducts from "../Pages/LikeCard/GetProducts/GetProducts";
import CheckBalanceOneCard from "../Pages/OneCard/CheckBalanceOneCard/CheckBalanceOneCard";
import ViewProductsOneCard from "../Pages/OneCard/ViewProducts/ViewProductsOneCard";
import DonePurchase from "../Pages/Purchase/DonePurchase/DonePurchase";
import GetAllBalance from "../Pages/FlutterOrderController/GetAllBalance/GetAllBalance";
import CurrentInventoryBalance from "../Pages/Statements/CurrentInventoryBalance/CurrentInventoryBalance";
import runningPurchase from "../Pages/Purchase/runningPurchase/runningPurchase";
import GetProductsById from "../Pages/LikeCard/GetProductsById/GetProductsById";
import ViewUsers from "../Pages/UserManagment/ViewUsers/ViewUsers";
import AllProducts from "../Pages/Components/AllProducts/AllProducts";
const Component = {
  // Layout
  Vendor,
  Auth,
  Dashboard,
  Contact,
  Login,
  Navber,
  Sildebar,
  Error,
  Profile,
  CheckBalance,
  ViewCategories,
  Home,
  GetOrders,
  GetProducts,
  CheckBalanceOneCard,
  ViewProductsOneCard,
  DonePurchase,
  GetAllBalance,
  CurrentInventoryBalance,
  runningPurchase,
  GetProductsById,
  ViewUsers,
  AllProducts
};

export default Component;
