import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import "./Paginator.scss";

function CustomPagination({ currentPage, totalPages, handlePageChange, indexOfLastRow, reportData }) {
  const [maxPagesToShow, setMaxPagesToShow] = useState(12);

  useEffect(() => {
    const handleResize = () => {
      const newMaxPagesToShow = window.innerWidth < 600 ? 5 : 12; // Set the breakpoint according to your design needs
      setMaxPagesToShow(newMaxPagesToShow);
    };

    // Initial call
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const middleIndex = Math.floor(maxPagesToShow / 2);

  let startPage = currentPage - middleIndex;
  let endPage = currentPage + middleIndex;

  if (startPage <= 0) {
    startPage = 1;
    endPage = Math.min(totalPages, maxPagesToShow);
  } else if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, totalPages - maxPagesToShow + 1);
  }


  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

      {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
        <Pagination.Item
          key={startPage + index}
          active={startPage + index === currentPage}
          onClick={() => handlePageChange(startPage + index)}
        >
          {startPage + index}
        </Pagination.Item>
      ))}

      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={indexOfLastRow >= (reportData?.length || 0)} // Add null check for reportData
      />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  );
}

export default CustomPagination;
